/* eslint-disable react/jsx-filename-extension */
import React from "react";
import { graphql } from "gatsby";
import {
  mergeCraftPreview,
  resolveEvent,
  resolveImage,
  resolveMeta,
} from "@dataResolvers";
import { Events as PageTemplate } from "@pageTemplates";
import moment from "moment-timezone";

export const query = graphql`
  query {
    craft {
      entries(section: "event") {
        ... on Craft_event_event_Entry {
          type: typeHandle
          title
          url
          slug
          eventType
          metaDescription
          eventImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          eventStartTime
          showDate
          eventEndTime
          repeats
        }
      }
      entry(section: "events") {
        ... on Craft_events_events_Entry {
          # meta
          url
          slug
          title
          metaImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          metaDescription
          metaTitle
          uid
          heroHeading
          heroSubheading
          heroBackgroundImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          featuredEvent {
            ... on Craft_event_event_Entry {
              type: typeHandle
              title
              url
              slug
              eventType
              metaDescription
              eventImage {
                width
                height
                hasFocalPoint
                slug
                status
                title
                focalPoint
                url
              }
              eventStartTime
              showDate
              eventEndTime
              repeats
            }
          }
        }
      }
    }
  }
`;

const previewQuery = `

query {
  craft {
    entries(section: "event") {
      ... on Craft_event_event_Entry {
        type: typeHandle
        title
        url
        slug
        eventType
        metaDescription
        eventImage {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        eventStartTime
showDate
        repeats
      }
    }
    entry(section: "events") {
      ... on Craft_events_events_Entry {
        uid
        heroHeading
        heroSubheading
        heroBackgroundImage {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        scrimOpacity
        featuredEvent {
          ... on Craft_event_event_Entry {
            type: typeHandle
            title
            url
            slug
            eventType
            metaDescription
            eventImage {
              width
              height
              hasFocalPoint
              slug
              status
              title
              focalPoint
              url
            }
            eventStartTime
showDate
            repeats
          }
        }
      }
    }
  }
}
`;

const dataResolver = ({ craft }) => {
  const {
    // meta
    title,
    metaTitle,
    metaImage,
    metaDescription,
    slug,
    url,
    // other
    heroHeading,
    heroSubheading,
    heroBackgroundImage,
    featuredEvent,
  } = craft.entry;

  const events = craft.entries
    .map(e => resolveEvent(e))
    .filter(
      e =>
        e.slug !== featuredEvent[0].slug &&
        // get events from this morning onward
        e.orderTime >= moment().startOf("day").utc().valueOf()
    )
    .sort((a, b) => a.orderTime - b.orderTime);

  const pastevents = craft.entries
    .map(e => resolveEvent(e))
    .filter(
      e =>
        e.slug !== featuredEvent[0].slug &&
        // get events from this morning onward
        e.orderTime < moment().startOf("day").utc().valueOf()
    )
    .sort((a, b) => b.orderTime - a.orderTime);

  return {
    meta: resolveMeta({
      title,
      metaTitle,
      metaImage,
      metaDescription,
      slug,
      url,
    }),
    hero: {
      heading: heroHeading,
      content: heroSubheading,
      image: resolveImage(heroBackgroundImage),
    },
    featured: featuredEvent
      .map(e => resolveEvent(e))
      .map(t => {
        return {
          label: "Featured Event",
          title: t.title,
          name: t.title,
          description: t.excerpt,
          showDate: t.showDate,
          type: {
            text: t.type,
            icon: t.type,
          },
          tag: { text: t.type, slug: t.type },
          time: t.repeats ? t.time : `${t.date} ${t.times}`,
          mediaUrl: t.mediaUrl,
          mediaEmbed: t.mediaEmbed,
          image: t.image,
          link: { url: t.url, text: t.label },
        };
      })[0],
    upcoming: events.map(t => {
      return {
        name: t.title,
        description: t.excerpt,
        showDate: t.showDate,
        type: {
          text: t.type,
          icon: t.type,
        },
        tag: { text: t.type, slug: t.type },
        time: t.repeats ? t.time : `${t.date} ${t.times}`,
        mediaUrl: t.mediaUrl,
        mediaEmbed: t.mediaEmbed,
        image: t.image,
        link: { url: t.url, text: t.label },
      };
    })[0],
    events: [...events, ...pastevents].map(t => {
      return {
        name: t.title,
        description: t.excerpt,
        showDate: t.showDate,
        type: {
          text: t.type,
          icon: t.type,
        },
        time: t.repeats ? t.time : `${t.date} ${t.times}`,
        mediaUrl: t.mediaUrl,
        mediaEmbed: t.mediaEmbed,
        image: t.image,
        button: { url: t.url, text: t.label },
      };
    }),
  };
};

const QueryContainer = ({ data, pageContext }) => {
  const { slug: _slug } = pageContext;
  const merged = mergeCraftPreview(data, previewQuery, {
    slug: _slug,
    site: process.env.GATSBY_CRAFT_SITE_HANDLE,
  });

  // When previewing unpublished pages, merged will be empty until
  // the preview reqeust has finished, so returning null to avoid
  // component errors about missing content.
  if (!merged) {
    return null;
  }

  const resolvedData = dataResolver(merged);

  return <PageTemplate data={resolvedData} />;
};

export default QueryContainer;
